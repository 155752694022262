<template>
  <div class="schedule-list">
    <b-tabs content-class="m-0" v-model="selectedTab">
      <b-tab
        :title="tab.title"
        :active="tab.isActive"
        v-for="(tab, i) in tabs"
        :key="i"
        title-link-class="schedule-list__tab"
      >
        <div
          v-if="
            tab.value == tabs[selectedTab].value &&
            !isLoadingClasses &&
            (items || []).length > 0
          "
          class="management-list p-4"
        >
          <b-table
            small
            borderless
            responsive
            hover
            :items="items"
            :fields="fields"
            :per-page="perPage"
            :current-page="currentPage"
            v-if="(items || []).length > 0"
          >
            <template #cell(Class_Name)="data">
              <span
                class="learner-text-bold"
                @click="redirect('detail', data.item.id)"
                >{{ data.item.Class_Name }}</span
              >
            </template>
            <template #cell(Sessions)="data">
              <span
                @click="redirect('detail', data.item.id)"
                class="learner-text"
              >
                {{ data.item.Sessions }}
              </span>
            </template>
            <template #cell(Locations)="data">
              <span
                @click="redirect('detail', data.item.id)"
                class="learner-text"
              >
                {{ data.item.Locations }}
              </span>
            </template>
            <template #cell(Enroled_users)="data">
              <span
                @click="redirect('enrollement', data.item.id)"
                class="learner-text"
              >
                {{ data.item.Enroled_users }}
              </span>
            </template>
            <template #cell(Co_cordinator)="data">
              <span
                class="learner-text"
                @click="redirect('detail', data.item.id)"
              >
                {{ data.item.Co_cordinator }}
              </span>
            </template>
            <template #cell(Start_date)="data">
              <span
                class="learner-text"
                @click="redirect('detail', data.item.id)"
              >
                {{ data.item.Start_date }}
              </span>
            </template>
            <template #cell(Enddate)="data">
              <span
                class="learner-text"
                @click="redirect('detail', data.item.id)"
              >
                {{ data.item.Enddate }}
              </span>
            </template>
            <template #cell(options)="data">
              <b-button
                :id="'popover' + data.item.id"
                class="b-transparent float-right"
                v-if="
                  selectedStatus != 'canceled' && selectedStatus != 'completed'
                "
              >
                <img src="@/assets/three-dots.svg" alt />
              </b-button>
              <b-popover
                :target="'popover' + data.item.id"
                triggers="focus"
                placement="top"
              >
                <div class="edit-option">
                  <div @click="editClass(data.item.id)">Edit</div>
                  <div class="separator"></div>
                  <div @click="cancelClass(data.item.id)">Cancel</div>
                  <div class="separator"></div>
                  <div
                    @click="deleteClass(data.item.id)"
                    @hide="onDeleteConfirm"
                  >
                    Delete
                  </div>
                </div>
              </b-popover>
            </template>
          </b-table>
          <f2f-pagination
            :total-rows="rows"
            v-mode="currentPage"
            @page="onPage"
          />
        </div>
        <div v-if="isLoadingClasses" class="no-record-holder">
          <b-spinner label="Loading..."></b-spinner>
        </div>
        <div
          v-if="!isLoadingClasses && (getFilteredClasses || []).length == 0"
          class="no-record-holder"
        >
          <div>
            <img src="@/assets/wrappers/norecord.svg" />
            <div>{{ tabs[selectedTab].noRecordMessage }}</div>
          </div>
        </div>
      </b-tab>
    </b-tabs>
    <b-modal
      v-model="editClassModal"
      hide-footer
      hide-header
      no-close-on-esc
      no-close-on-backdrop
      size="lg"
    >
      <div class="edit-class">
        <AddOrEditClass :classId="currentClassId" @onEdit="onEdit" />
      </div>
    </b-modal>
    <f2f-modal
      :display="confirmCancel"
      @hide="onCancel"
      type="warning"
      title="Cancel class?"
      body="Are you sure you want to cancel this Class? "
    />
    <b-modal
      id="cancel-modal"
      v-model="unableCancel"
      hide-footer
      hide-header
      centered
    >
      <CancelModal
        :classId="currentClassId"
        @error="onCancelError"
        @hideReason="hideReason"
        @reload="reloadUI"
      />
    </b-modal>
    <f2f-modal
      :display="confirmDelete"
      @hide="onDeleteConfirm"
      type="warning"
      title="Delete class?"
      body="Are you sure you want to delete this Class?"
    />
  </div>
</template>

<script>
import { mapGetters } from "vuex";
import AddOrEditClass from "./../schedule/AddOrEditClass.vue";
import CancelModal from "./../schedule/CancelModal.vue";

export default {
  components: { AddOrEditClass, CancelModal },
  props: ["classInfo"],
  data() {
    return {
      selectedTab: 0,
      editClassModal: false,
      confirmCancel: false,
      unableCancel: false,
      confirmDelete: false,
      currentClassId: -1,
      tabs: [
        {
          title: "Future",
          isActive: true,
          value: "future",
          noRecordMessage: "Currently, there is no future classes happening!"
        },
        {
          title: "Ongoing",
          isActive: false,
          value: "ongoing",
          noRecordMessage: "Currently, there is no ongoing classes happening!"
        },
        {
          title: "Completed",
          isActive: false,
          value: "completed",
          noRecordMessage:
            "Currently, there is no completed classes happening!!"
        },
        {
          title: "Cancelled",
          isActive: false,
          value: "canceled",
          noRecordMessage: "No cancelled classes found!!"
        }
      ],
      perPage: 10,
      currentPage: 1,
      fields: [
        {
          key: "Class_Name",
          label: "Class Name"
        },
        {
          key: "Sessions",
          label: "Sessions"
        },
        {
          key: "Locations",
          label: "Locations"
        },
        {
          key: "Enroled_users",
          label: "Enrolled users",
          thStyle: { width: "10%" }
        },
        {
          key: "Co_cordinator",
          label: "Course cordinator",
          thStyle: { width: "20%" }
        },
        {
          key: "Start_date",
          label: "Start date",
          thStyle: { width: "10%" }
        },
        {
          key: "Enddate",
          label: "End date"
        },
        {
          key: "options",
          label: ""
        }
      ],
      error: "",
      onError: false
    };
  },
  mounted() {
    this.$store.dispatch("listFilteredClasses", {
      course_id: this.$route.params.id,
      status: this.tabs[0].value
    });
  },
  watch: {
    selectedTab(selected) {
      this.$store.dispatch("listFilteredClasses", {
        course_id: this.$route.params.id,
        status: this.tabs[selected].value
      });
    }
  },
  computed: {
    ...mapGetters([
      "getFilteredClasses",
      "isLoadingClasses",
      "getIsInstructor"
    ]),
    selectedStatus() {
      return this.tabs[this.selectedTab].value;
    },
    items() {
      return (this.getFilteredClasses || []).map((cls) => {
        return {
          Class_Name: cls.title,
          Sessions: cls.sessions_count,
          Locations: cls.location.name,
          Enroled_users: cls.registered_students_count,
          Co_cordinator: cls.coordinator
            ? cls.coordinator.first_name + " " + cls.coordinator.last_name
            : "",
          Start_date: this.formatDateByPattern(cls.start_date_at, "DD/MM/YYYY"),
          Enddate: this.formatDateByPattern(cls.end_date_at, "DD/MM/YYYY"),
          id: cls.id
        };
      });
    },
    rows() {
      return this.items.length;
    }
  },
  methods: {
    onPage(page) {
      this.perPage = page.perPage;
      this.currentPage = page.currentPage;
    },
    editClass(classId) {
      this.currentClassId = classId;
      this.editClassModal = true;
    },
    cancelClass(classId) {
      this.currentClassId = classId;
      this.confirmCancel = true;
    },
    async onCancel(userAction) {
      if (userAction) {
        try {
          this.confirmCancel = false;
          this.unableCancel = true;
        } catch {
          this.unableCancel = false;
        }
      }
      this.confirmCancel = false;
    },
    hideReason() {
      this.unableCancel = false;
    },
    deleteClass(classId) {
      this.currentClassId = classId;
      this.confirmDelete = true;
    },
    async onDeleteConfirm(userAction) {
      this.confirmDelete = false;
      if (userAction) {
        try {
          await this.$store.dispatch("deleteClass", {
            courseId: this.$route.params.id,
            classId: this.currentClassId
          });
          this.$store.dispatch("showNotification", {
            message: "Class deleted successfully!"
          });
          this.reloadUI();
        } catch (err) {
          this.isDeleteError = true;
          console.log(err);
        }
      }
    },
    reloadUI() {
      this.$store.dispatch("listAllClasses", {
        course_id: this.$route.params.id,
        isSilentLoad: true
      });
      this.$store.dispatch("listFilteredClasses", {
        course_id: this.$route.params.id,
        status: this.selectedStatus || "future"
      });
    },
    onCancelError() {
      this.isCancelError = true;
    },
    onEdit(edited) {
      this.editClassModal = false;
      edited && this.reloadUI();
    },
    redirect(name, classId) {
      this.$router.push({ name, params: { classId } });
    }
  }
};
</script>

<style lang="scss" scoped>
::v-deep #cancel-modal .modal-body {
  padding: 0px;
  .form-space {
    min-width: auto;
  }
}
.modal-content {
  padding: 1.5rem;
  border: none;
  border-left: 5px solid #ffaa15;
}
.schedule-list {
  .no-record-holder {
    height: 60vh;
    min-height: 300px;
    border: 1px solid #b2b2b2;
    text-align: center;
    display: flex;
    justify-content: center;
    align-items: center;
    background: white;

    img {
      width: 80px;
      margin-bottom: 1rem;
    }
  }
  .nav-tabs {
    border-bottom: none;
  }
  // Add deep for scoped plugin styles https://vue-loader.vuejs.org/guide/scoped-css.html#deep-selectors
  ::v-deep .schedule-list__tab {
    font-size: 14px;
    line-height: 16px;
    letter-spacing: 0.2px;
    color: $nav-primary-color;
    padding: 0.5rem 1rem 1rem 1rem;
    &.active {
      color: #333333;
      border: none;
      border-bottom: 5px solid $nav-primary-color;
      &:hover {
        border-color: $nav-primary-color;
      }
    }
    &:hover {
      border-color: transparent;
    }
  }
  img {
    cursor: pointer;
  }
  .list-header {
    padding-left: 20px;
    .header-text {
      display: flex;
      justify-content: space-between;
      .header-text__title {
        text-align: left;
        font-size: 24px;
        line-height: 20px;
        letter-spacing: 0.2px;
        color: #1176b2;
        padding-bottom: 20px;
        width: calc(100% - 200px);
        @include text-ellipsis;
      }
    }

    .header-content {
      display: flex;
      p {
        line-height: 16px;
        font-size: 14px;
        color: #52575c;
        margin: 0 4% 0 0;
        text-align: left;
        &.header-content__active {
          font-weight: 600;
          color: #25282b;
        }
      }
    }
  }
  .b-card-text {
    cursor: pointer;
  }
}

::v-deep {
  thead {
    font-family: Roboto;
    font-style: normal;
    font-weight: 500;
    font-size: 14px;
    line-height: 21px;
    letter-spacing: 0.1px;
    color: #a0a4a8;
  }
}
::v-deep tbody {
  font-family: Open Sans;
  font-style: normal;
  font-weight: 600;
  font-size: 14px;
  line-height: 16px;
}
::v-deep .nav-tabs {
  font-size: 14px;
}
.management-list {
  min-height: 350px;
  border: 1px solid #b2b2b2;
  background: white;
}

.management-listtable {
  min-height: 250px;
}

.learner-text-bold {
  font-family: Open Sans;
  font-style: normal;
  font-weight: 600;
  font-size: 14px;
  line-height: 16px;
  display: flex;
  align-items: center;
  color: #25282b;
  cursor: pointer;
  width: 205px;
  overflow: hidden;
  display: -webkit-box;
  -webkit-box-orient: vertical;
  -webkit-line-clamp: 2;
  overflow-wrap: break-word;
  word-break: break-all;
}
.learner-text {
  font-family: Open Sans;
  font-style: normal;
  font-weight: normal;
  font-size: 14px;
  line-height: 16px;
  letter-spacing: 0.2px;
  color: #52575c;
}
.learner-text:hover {
  color: #0043ac;
  cursor: pointer;
}

.b-transparent {
  margin-left: 10%;
  background: transparent;
  border: none;
  :focus {
    background: transparent;
  }
  img {
    position: relative;
    // bottom: 14px;
  }
}
.btn-secondary:hover {
  background: none;
}
.btn-secondary:focus {
  background: none;
}
.edit-option {
  background: #ffffff;
  border-radius: 4px;
  border: none;

  .separator {
    border: 0.5px solid #e8e8e8;
    margin-top: 0.5rem;
    margin-bottom: 0.5rem;
    width: 120px;
  }

  div {
    color: #52575c;
    font-size: 16px;
  }

  div:hover {
    color: #0043ac;
    cursor: pointer;
  }
}
.pagination-text {
  font-family: Roboto;
  font-style: normal;
  font-weight: normal;
  font-size: 14px;
  line-height: 22px;
  letter-spacing: 0.1px;
  color: #757575;
}
::v-deep.table td {
  vertical-align: middle !important;
}
</style>
