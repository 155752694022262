<template>
  <div class="f2f-schedule">
    <f2f-alert
      :message="getNotification.message"
      :display="getNotification.display"
      @hide="hideNotification"
    />
    <div class="f2f-schedule__container">
      <b-row class="m-0">
        <b-col cols="12">
          <div class="f2f-schedule__head">
            <div>
              <div class="schedule-head__title">Classes</div>
              <div class="schedule-head__section">Management</div>
            </div>
            <EnrollComponent />
          </div>
        </b-col>
      </b-row>

      <b-row class="mt-4 m-0">
        <b-col cols="12">
          <div v-if="!getLoadingAllClasses">
            <EmptyClasses
              message="No classes assigned."
              v-if="getAllClasses.length == 0"
            />
            <ManagementList v-else />
          </div>
          <div class="loader" v-if="getLoadingAllClasses">
            <b-spinner label="Loading..."></b-spinner>
          </div>
        </b-col>
      </b-row>
    </div>
  </div>
</template>

<script>
import ManagementList from "@/components/classes/management/List.vue";
import EnrollComponent from "@/components/classes/management/EnrollUserComponent.vue";
import EmptyClasses from "@/components/classes/Empty.vue";
import { mapGetters } from "vuex";
import Vue from "vue";

export default Vue.extend({
  components: { ManagementList, EnrollComponent, EmptyClasses },
  data() {
    return {};
  },
  computed: {
    ...mapGetters(["getAllClasses", "getNotification", "getLoadingAllClasses"])
  },
  mounted() {
    this.$store.dispatch("listAllClasses", {
      course_id: this.$route.params.id
    });
    this.$store.dispatch("getCourseDetails", this.$route.params.id);
  },
  methods: {
    hideNotification() {
      this.$store.dispatch("hideNotification");
    }
  }
});
</script>

<style lang="scss" scoped>
.f2f-schedule {
  .loader {
    margin-top: 20vh;
    text-align: center;
  }
  .f2f-schedule__container {
    max-width: 1280px;
    min-width: 900px;
    margin: 0 auto;
    .f2f-schedule__head {
      display: flex;
      justify-content: space-between;
      align-items: center;
      padding: 2rem 0rem;
      border-bottom: 0.5px solid #e8e8e8;

      .schedule-head__title {
        font-size: 12px;
        line-height: 16px;
        letter-spacing: 0.2px;
        color: #4c4c4c;
      }

      .schedule-head__section {
        font-weight: bold;
        font-size: 38px;
        line-height: 32px;
        letter-spacing: 0.1px;
        color: #333333;
      }
    }

    .f2f-schedule-info {
      .class-info__head {
        font-family: Roboto;
        font-style: normal;
        font-weight: 500;
        font-size: 16px;
        line-height: 24px;
        letter-spacing: 0.1px;
        color: #25282b;
        margin-bottom: 6px;
      }
      .class-info__text {
        font-family: Roboto;
        font-size: 16px;
        line-height: 24px;
        letter-spacing: 0.1px;
        color: #52575c;
      }
    }
  }
}
</style>
